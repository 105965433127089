<template>
  <DarkOverlay :key="key" :shown="shown" @click="hide" />

  <transition
    :css="false"
    @before-enter="beforeEnter"
    @enter="onEnter"
    @leave="onLeave"
  >
    <div v-if="shown" ref="wrap" class="modal-wrapper" @mousedown="hide">
      <div
        v-bind="$attrs"
        ref="modal"
        :class="{
          modal: true,
          'modal-dialog': true,
          'modal-no-header': !useHeader,
          [`modal-dialog-size-${size}`]: true
        }"
        @mouseup.stop
        @mousedown.stop
      >
        <transition name="fade">
          <div v-if="modalLoading" class="modal-preloader">
            <AppPreloader />
          </div>
        </transition>

        <div v-if="useHeader" class="modal-heading">
          <div class="row">
            <div class="col-10 align-self-end">
              <div class="d-flex align-items-center">
                <button
                  v-if="inserted"
                  class="back me-3 ps-0"
                  @click="$emit('back')"
                >
                  <svg-icon icon="back-arrow" />
                </button>

                <h2 class="modal-title">{{ title }}</h2>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-end">
              <button class="close" @click="hide">
                <svg-icon icon="close" />
              </button>
            </div>
          </div>
        </div>

        <div v-if="$slots['modal-tabs']" class="modal-tabs-content-wrapper">
          <slot name="modal-tabs" />
        </div>

        <div v-if="$slots.tabs" class="modal-tabs">
          <slot name="tabs" />
        </div>

        <div class="modal-tabs-content">
          <slot name="tabs-content" />
        </div>

        <div v-if="$slots.default" class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";
import SvgIcon from "@/components/ui/SvgIcon";
import DarkOverlay from "@/components/ui/DarkOverlay";
import { mapMutations } from "vuex";
import AppPreloader from "@/components/ui/AppPreloader";

export default {
  name: "Dialog",
  components: { AppPreloader, DarkOverlay, SvgIcon },
  inheritAttrs: false,
  props: {
    title: { type: String, required: false },
    id: { type: String, default: "" },
    useHeader: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    inserted: { type: Boolean, default: false },
    modalLoading: { type: Boolean, default: false },
    canClose: { type: Boolean, default: true },
    size: {
      type: String,
      default: "lg",
      validator: s => ["xs", "sm", "md", "lg", "xl"].indexOf(s)
    }
  },
  emits: ["hide", "back"],

  data() {
    return {
      shown: this.opened,
      key: Math.random()
    };
  },

  watch: {
    opened(status) {
      this.shown = status;
      // if (status) {
      //   document.body.style.overflow = "hidden";
      // } else {
      //   document.body.style.overflow = "auto";
      // }
      // this.toggleOverlay(status);
    }
  },

  methods: {
    ...mapMutations(["toggleOverlay"]),
    hide() {
      if (!this.canClose) return;
      this.shown = false;
      this.$emit("hide");
    },

    beforeEnter(wrapper) {
      const modal = wrapper.children[0];
      gsap.set(modal, {
        y: 100,
        scale: 1.2,
        autoAlpha: 0
      });
    },

    onEnter(wrapper, done) {
      gsap.to(wrapper, {
        autoAlpha: 1,
        duration: 0.4
      });

      gsap.to(this.$refs.modal, {
        y: 0,
        scale: 1,
        autoAlpha: 1,
        duration: 0.4,
        onComplete: done
      });
    },
    onLeave(wrapper, done) {
      const modal = wrapper.children[0];
      this.$store.dispatch("toggleOverlay", false);
      gsap.to(modal, {
        duration: 0.4,
        y: 100,
        scale: 1.2,
        autoAlpha: 0
      });

      gsap.to(wrapper, {
        alpha: 0,
        duration: 0.4,
        easing: "none",
        delay: 0.1,
        onComplete: done
      });
    },

    onKeyPress({ key }) {
      if (key.toLocaleLowerCase() === "escape") {
        this.hide();
      }
    }
  },

  mounted() {
    this.modal = this.$refs.modal;
    this.wrap = this.$refs.wrap;

    document.addEventListener("keypress", this.onKeyPress);
  },

  beforeUnmount() {
    document.removeEventListener("keypress", this.onKeyPress);
  }
};
</script>

<style lang="scss">
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, 0.15);
  display: flex;
  justify-content: center;
  z-index: 9999999999999999;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 0;
  @extend %hideScrollBar;
  opacity: 0;
}

.modal {
  background-color: #fff;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  &-dialog {
    $padding-bottom: 30px;
    $padding-x: 3.9em;
    width: 100%;
    padding: 20px 0 0 0;
    border-radius: $field-radius;
    box-shadow: $shadow-700;
    height: max-content;
    transform: translateY(100px);
    opacity: 0;

    button.back {
      font-size: 20px;
      color: $color-primary-blue;

      @media (any-hover: hover) {
        transition: color 0.25s;
        &:hover {
          color: $color-contrast-dark;
        }
      }
    }

    &-size-lg {
      max-width: 560px;
    }

    &-size-sm {
      max-width: 360px;
    }

    &-size-xs {
      max-width: 300px;
      padding: 0 !important;
      .modal-body {
        padding: 30px 25px 10px 25px !important;
      }
    }

    .modal-heading {
      padding: 0 $padding-x - 1em 2em $padding-x;
    }

    .modal-tabs {
      .tabs {
        padding-left: $padding-x;
        padding-right: $padding-x;
      }
    }

    .modal-tabs-content {
      .tab-panel {
        padding-left: $padding-x;
        padding-right: $padding-x;
        padding-bottom: $padding-bottom;
      }
    }

    .modal-body {
      padding: 0 $padding-x $padding-bottom $padding-x;
      @extend %hideScrollBar;
    }

    .modal-tabs-content-wrapper {
      --tab-content-padding: #{$padding-x};
      padding-bottom: $padding-bottom;
    }

    &.modal-no-header {
      padding-top: 2.5em;
      padding-bottom: 2em;
    }
  }
}

button.close {
  width: 2.8em;
  height: 2.8em;
  background-color: $grey-400;
  border-radius: 50%;
  .icon {
    font-size: 0.84em;
  }
}

.modal-preloader {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary-blue;
  border-radius: inherit;
}
</style>

<template>
  <button class="button-back" @click="$router.back()">
    <svg-icon icon="back" />
  </button>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "BackButton",
  components: { SvgIcon }
};
</script>

<style scoped lang="scss">
.button-back {
  font-size: 2rem;
  @media (any-hover: hover) {
    transition: 0.2s;
    &:hover {
      color: $color-primary-blue;
    }
  }
}
</style>

<template>
  <div class="spinner">
    <div class="spinner-inner">
      <div class="bounce1" />
      <div class="bounce2" />
      <div class="bounce3" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPreloader"
};
</script>

<style lang="scss" scoped>
$color: currentColor;

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    font-size: 1em;
    margin: 0 auto;
    width: 5em;
    text-align: center;
    color: inherit;

    & > div {
      width: 0.65em;
      height: 0.65em;
      background-color: $color;

      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &:not(:last-child) {
        margin-right: 0.15em;
      }
    }
  }
}

.spinner .bounce1 {
  animation-delay: -0.32s;
  background-color: $color;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
  background-color: $color;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
